import { createContainer, useContainer } from "unstated-next";
import {
  ARIZONA_APPAREL_CLIENT_ID,
  BATCH_TASKS_ASSIGNEE_ID,
  BRIOCHE_PASQUIER_CLIENT_ID,
  CHRISTOFLE_ASIA_CLIENT_ID,
  CHRISTOFLE_BRAZIL_CLIENT_ID,
  CHRISTOFLE_CLIENT_ID,
  CHRISTOFLE_JAPAN_CLIENT_ID,
  CHRISTOFLE_LATAM_CLIENT_ID,
  CHRISTOFLE_SILVER_CLIENT_ID,
  CLAREO_CLIENT_ID,
  CLAREO_WAREHOUSE_ID,
  COLD_AND_CO_CLIENT_ID,
  COLORCON_CLIENT_ID,
  CUSTOMER_SUCCESS_EMAILS,
  DEMO_WAREHOUSE_FR_ID,
  DEMO_WAREHOUSE_ID,
  DEV_EMAILS,
  DREAMPATH_CLIENT_ID,
  emailsAuthorizedOnOrderPreparation,
  emailsAuthorizedOnTms,
  employeeEmailsAuthorizedOnNewTariffNumberFeatures,
  FREIGHT_FORWARDERS_IDS_ALLOWED_TO_USE_QUOTE_EXTRACTION,
  FUSALP_WAREHOUSE_ID,
  GINGER_EMAILS,
  isDefined,
  KSGB_CLIENT_ID,
  LE_COQ_SPORTIF_CLIENT_ID,
  LOGISTIC_AGENTS_IDS_ALLOWED_TO_USE_INVOICE_EXTRACTION,
  MR_BRICOLAGE_CLIENT_ID,
  // NV_GALLERY_CLIENT_ID,
  NV_GALLERY_WAREHOUSE_ID,
  OPS_SPECIALISTS_AND_PRICING_EXPERTS_AND_TLO,
  OTHER_DEMO_WAREHOUSE_ID,
  PAPRIKA_EMAILS,
  RHENUS_CHRISTOFLE_WAREHOUSE_ID,
  SOLIDO_CLIENT_ID,
  TMS_SANDBOX_CLIENT_ID,
  ZADIG_VOLTAIRE_CLIENT_ID,
} from "@ovrsea/ovrutils";
import { includes } from "lodash-es";
import { useSession } from "./SessionProvider";

type Session = ReturnType<typeof useSession>["session"];

type ComputedFeatureFlag = {
  arePurchaseOrdersAvailable: boolean;
  canAccessNewPropositionTable: boolean;
  canAccessSchedulesOnTraffics: boolean;
  canAccessTms: boolean;
  canAccessWinddleReporting: boolean;
  canCreatePickings: boolean;
  canCreatePropositions: boolean;
  canRequestOrderPreparation: boolean;
  canScheduleAppointments: boolean;
  canSeeEnhancedReportingWithPo: boolean;
  canSendBookingRequests: boolean;
  canShareTrackingViaTrackingCard: boolean;
  canUseDropbox: boolean;
  canUseFfInvoiceExtraction: boolean;
  canUseFfQuoteExtraction: boolean;
  canUseNewTariffNumberFeatures: boolean;
  displayUsedForOpflowTag: boolean;
  hasAccessToOtherServicesCategory: boolean;
  isAllowedToDuplicatedExpiredQuotation: boolean;
  isAllowedToEditDocuments: boolean;
  isBatchTaskAssignee: boolean;
  isClientSelfManagingWarehouses: boolean;
  isCustomerSuccess: boolean;
  isDemoHermes: boolean;
  isDemoOneChain: boolean;
  isDev: boolean;
  isEmployee: boolean;
  isFreightForwarderUser: boolean;
  isGinger: boolean;
  isPaprika: boolean;
  isPinkAtlas: boolean;
  isTrackingInEditModeByDefault: boolean;
  isWarehouseManager: boolean;
  isWarehouseManagerUser: boolean;
  showComplexAppointments: boolean;
  showNotificationSettings: boolean;
  showQuoteRequests: boolean;
  showReservedProductsInContainer: boolean;
};

export type StaticFeatureFlag = {
  acceptQuoteRequestWithProposition: boolean;
  apiDocLink: string;
  branding: "onechain" | "ovrsea";
  canAlwaysUpdateCargo: boolean;
  canEditTracking: boolean;
  canRecordVoiceMessages: boolean;
  canUpdateShipment: boolean;
  canUseCustomClearance: boolean;
  displayAllDocumentTypes: boolean;
  displayAssignedEmployee: boolean;
  displayCo2CompensationInBookingConfirmation: boolean;
  displayNewFeatureBadges: boolean;
  displayOvrseaConditions: boolean;
  isAllowedToActivateTracking: boolean;
  isAllowedToAssignRequestToFreightForwarder: boolean;
};

export const defaultStaticFeatureFlag: StaticFeatureFlag = {
  acceptQuoteRequestWithProposition: false,
  // Technically both branding and apiDocLink are NOT feature flags but config elements
  apiDocLink: "https://apidoc.ovrsea.com/",
  branding: "ovrsea",
  canAlwaysUpdateCargo: false,
  canEditTracking: false,
  canRecordVoiceMessages: __ATLAS__,
  canUpdateShipment: false,
  canUseCustomClearance: true,
  displayAllDocumentTypes: false,
  displayAssignedEmployee: true,
  displayCo2CompensationInBookingConfirmation: true,
  displayNewFeatureBadges: true,
  displayOvrseaConditions: true,
  isAllowedToActivateTracking: false,
  isAllowedToAssignRequestToFreightForwarder: false,
};

export const WAREHOUSE_ID = [
  DEMO_WAREHOUSE_ID,
  DEMO_WAREHOUSE_FR_ID,
  OTHER_DEMO_WAREHOUSE_ID,
  RHENUS_CHRISTOFLE_WAREHOUSE_ID,
  NV_GALLERY_WAREHOUSE_ID,
  CLAREO_WAREHOUSE_ID,
  FUSALP_WAREHOUSE_ID,
];

export const isWarehousePlanningActiveOnLocation = (
  locationId: null | string,
) => isDefined(locationId) && WAREHOUSE_ID.includes(locationId);

const isUser = (
  session: Session,
): session is { person: { __typename: "User" } } & Session => {
  return session?.person.__typename === "User";
};

const pinkAtlasEmails = [
  "eleonore.bossi@ovrsea.com",
  "kendall.jaquez@ovrsea.com",
];

const DEMO_CLIENT = [ARIZONA_APPAREL_CLIENT_ID, TMS_SANDBOX_CLIENT_ID];

const PICKING_CLIENTS = [
  TMS_SANDBOX_CLIENT_ID,
  CHRISTOFLE_CLIENT_ID,
  CHRISTOFLE_LATAM_CLIENT_ID,
  CHRISTOFLE_ASIA_CLIENT_ID,
  CHRISTOFLE_BRAZIL_CLIENT_ID,
  CHRISTOFLE_JAPAN_CLIENT_ID,
  CHRISTOFLE_SILVER_CLIENT_ID,
];

const WINDDLE_REPORTING_CLIENTS = [ZADIG_VOLTAIRE_CLIENT_ID];

const ENHANCE_REPORTING_WITH_PO_CLIENTS = [
  KSGB_CLIENT_ID,
  ARIZONA_APPAREL_CLIENT_ID,
];

const useFeatureFlagContainer = (
  usedFeatureFlag: StaticFeatureFlag | undefined = defaultStaticFeatureFlag,
): ComputedFeatureFlag & StaticFeatureFlag => {
  const { session } = useSession();
  const isWarehouseManagerUser =
    session?.person.__typename === "LogisticAgentUser" &&
    session.person.logisticAgent.type === "warehouse_manager";
  const isGinger = includes(GINGER_EMAILS, session?.person.email);
  const isPaprika = includes(PAPRIKA_EMAILS, session?.person.email);
  const isEmployee = session?.person.__typename === "Employee";
  const isFreightForwarderUser =
    session?.person.__typename === "LogisticAgentUser" &&
    session.person.logisticAgent.type === "freight_forwarder";

  const isAllowedToEditDocuments = isFreightForwarderUser || isEmployee;
  /* const isNVGallery =
    isUser(session) && session?.person.client.id === NV_GALLERY_CLIENT_ID; */
  const isClareo =
    isUser(session) && session?.person.client.id === CLAREO_CLIENT_ID;

  const isDev = isDefined(session) && DEV_EMAILS.includes(session.person.email);

  const canSendBookingRequests =
    isUser(session) &&
    session.person.client.connections.buyers.length > 0 &&
    usedFeatureFlag.branding === "onechain";

  const employeeSquadIsUsa =
    session?.person.__typename === "Employee" &&
    session.person.squadName === "usa";
  const employeeIsOpsSpecialistOrPricingExpert =
    session?.person.__typename === "Employee" &&
    OPS_SPECIALISTS_AND_PRICING_EXPERTS_AND_TLO.includes(session?.person.email);
  const isAllowedToDuplicatedExpiredQuotation = Boolean(
    employeeSquadIsUsa || employeeIsOpsSpecialistOrPricingExpert,
  );

  const isBatchTaskAssignee = session?.person.id === BATCH_TASKS_ASSIGNEE_ID;

  const hasAccessToOtherServicesCategory =
    session?.person.email === "manon.hie@ovrsea.com";

  const canAccessTms = Boolean(
    session && emailsAuthorizedOnTms.includes(session.person.email),
  );

  const canRequestOrderPreparation =
    isUser(session) &&
    PICKING_CLIENTS.includes(session.person.client.id) &&
    emailsAuthorizedOnOrderPreparation.includes(session.person.email);

  const canUseNewTariffNumberFeatures = includes(
    employeeEmailsAuthorizedOnNewTariffNumberFeatures,
    session?.person?.email,
  );

  const isDemoOneChain =
    isUser(session) && DEMO_CLIENT.includes(session.person.client.id);
  const isDemoHermes = __DEMO__;

  const canCreatePickings =
    isUser(session) &&
    PICKING_CLIENTS.includes(session.person.client.id) &&
    usedFeatureFlag.branding === "onechain";

  const isClientSelfManagingWarehouses =
    isUser(session) && session.person.client.managedWarehouses.length > 0;
  const isWarehouseManager =
    session?.person.__typename === "LogisticAgentUser" &&
    session.person.logisticAgent.type === "warehouse_manager";

  const canAccessWinddleReporting =
    isUser(session) &&
    WINDDLE_REPORTING_CLIENTS.includes(session.person.client.id);

  const arePurchaseOrdersAvailable =
    isUser(session) &&
    (usedFeatureFlag.branding === "onechain" ||
      session.person.client.productSource === "purchase_orders");

  const canUseFfQuoteExtraction =
    emailsAuthorizedOnTms.includes(session?.person.email || "") ||
    (session?.person.__typename === "LogisticAgentUser" &&
      FREIGHT_FORWARDERS_IDS_ALLOWED_TO_USE_QUOTE_EXTRACTION.includes(
        session.person.logisticAgent.id,
      ));

  const canUseFfInvoiceExtraction =
    emailsAuthorizedOnTms.includes(session?.person.email || "") ||
    (session?.person.__typename === "LogisticAgentUser" &&
      LOGISTIC_AGENTS_IDS_ALLOWED_TO_USE_INVOICE_EXTRACTION.includes(
        session.person.logisticAgent.id,
      ));

  const isCustomerSuccess = CUSTOMER_SUCCESS_EMAILS.includes(
    session?.person.email ?? "",
  );

  const clientIdsWithAccessToSchedules = [
    COLORCON_CLIENT_ID,
    COLD_AND_CO_CLIENT_ID,
    LE_COQ_SPORTIF_CLIENT_ID,
    DREAMPATH_CLIENT_ID,
    BRIOCHE_PASQUIER_CLIENT_ID,
    SOLIDO_CLIENT_ID,
    MR_BRICOLAGE_CLIENT_ID,
  ];
  const canAccessSchedulesOnTraffics =
    isDev ||
    isPaprika ||
    isDemoHermes ||
    (isUser(session) &&
      clientIdsWithAccessToSchedules.includes(session.person.client.id));

  const canSeeEnhancedReportingWithPo =
    isUser(session) &&
    ENHANCE_REPORTING_WITH_PO_CLIENTS.includes(session.person.client.id);

  const emailsWithAccessToNewPropositionTable = [
    "amandine.belval@ovrsea.com",
    "corentin.charachon@ovrsea.com",
    "helene.droin@ovrsea.com",
    "heloise@ovrsea.com",
    "hugo.debengy@ovrsea.com",
    "lea.boubet@ovrsea.com",
    "louis.gas@ovrsea.com",
    "margaux@ovrsea.com",
    "vianney.martin@ovrsea.com",
  ];
  const canAccessNewPropositionTable =
    isDev ||
    (!!session?.person?.email &&
      emailsWithAccessToNewPropositionTable.includes(session.person.email));

  return {
    ...usedFeatureFlag,
    arePurchaseOrdersAvailable,
    canAccessNewPropositionTable,
    canAccessSchedulesOnTraffics,
    canAccessTms,
    canAccessWinddleReporting,
    canCreatePickings,
    canCreatePropositions: isFreightForwarderUser || isEmployee,
    canRequestOrderPreparation: canRequestOrderPreparation || isDemoOneChain,
    canScheduleAppointments: isFreightForwarderUser || isDemoOneChain,
    canSeeEnhancedReportingWithPo,
    canSendBookingRequests,
    canShareTrackingViaTrackingCard: isUser(session),
    canUseDropbox: isEmployee,
    canUseFfInvoiceExtraction,
    canUseFfQuoteExtraction,
    canUseNewTariffNumberFeatures,
    displayUsedForOpflowTag: isEmployee,
    hasAccessToOtherServicesCategory,
    isAllowedToDuplicatedExpiredQuotation,
    isAllowedToEditDocuments,
    isBatchTaskAssignee,
    isClientSelfManagingWarehouses,
    isCustomerSuccess,
    isDemoHermes,
    isDemoOneChain,
    isDev,
    isEmployee,
    isFreightForwarderUser,
    isGinger,
    isPaprika,
    isPinkAtlas: isEmployee && pinkAtlasEmails.includes(session?.person.email),
    isTrackingInEditModeByDefault:
      isFreightForwarderUser || isEmployee || isWarehouseManager,
    isWarehouseManager,
    isWarehouseManagerUser,
    showComplexAppointments: isClareo,
    showNotificationSettings: isUser(session),
    showQuoteRequests:
      !isFreightForwarderUser &&
      !isWarehouseManagerUser &&
      usedFeatureFlag.branding === "onechain",
    showReservedProductsInContainer: isDemoOneChain, // isGinger || isNVGallery, Fake data for now. Uncomment when this is ready
  };
};

const FeatureFlagProviderContainer = createContainer(useFeatureFlagContainer);

export const useFeatureFlag = () => useContainer(FeatureFlagProviderContainer);
export const FeatureFlagProvider = FeatureFlagProviderContainer.Provider;
