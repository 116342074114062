import type { CSSProperties } from "react";
import React from "react";
import type { Sx } from "@ovrsea/vulcan";
import { Body, Center, ThemeProvider } from "@ovrsea/vulcan";
import { Favicon } from "../Logos/Favicon";

const containerSx: Sx = {
  height: "100vh",
};

const faviconStyle: CSSProperties = {
  borderRadius: 4,
  boxShadow: "3px 3px 7px 3px rgba(140, 152, 164, 0.2)",
  height: 60,
  marginBottom: 24,
};

const MaintenancePage = () => {
  return (
    <ThemeProvider>
      <Center flexDirection="column" sx={containerSx}>
        <Favicon style={faviconStyle} />
        <Body marginBottom={8}>Hold tight! We are in maintenance.</Body>
        <Body isSecondary>
          The page you wish to access is currently under maintenance and will be
          back soon.
        </Body>
      </Center>
    </ThemeProvider>
  );
};

export default MaintenancePage;
