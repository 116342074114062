import { gql } from "@apollo/client/core";
import { clientForUserOnCheckSessionFragment } from "../clients/fragments";

const userScalarFragment = gql`
  fragment userScalarFields on User {
    id
    createdAt
    updatedAt
    email
    firstName
    followingUserIds
    isDecisionMaker
    language
    lastName
    name
    phone
    pictureUrl
    role
  }
`;

export const subscribedUserFragment = gql`
  fragment subscribedUserFields on User {
    id
    client {
      id
      name
    }
    email
    isDecisionMaker
    name
    phone
    pictureUrl
    role
    status
  }
`;

export const userSimpleScalarFragment = gql`
  fragment userSimpleScalarFields on User {
    id
    email
    language
    name
    phone
    pictureUrl
    role
    status
  }
`;

export const userForMessageScalarFragment = gql`
  fragment userForMessageScalarFields on User {
    id
    email
    isDecisionMaker
    name
    phone
    pictureUrl
  }
`;

export const userForCheckSessionFragment = gql`
  fragment userForCheckSessionFields on User {
    client {
      ...clientForUserOnCheckSessionFields
    }
    ...userScalarFields
  }
  ${userScalarFragment}
  ${clientForUserOnCheckSessionFragment}
`;
