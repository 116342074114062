import type { AppType } from "@ovrsea/ovrlib/components/App";
import AuthTokenProvider from "@ovrsea/ovrlib/components/App/AuthTokenProvider";
import SessionProvider from "@ovrsea/ovrlib/components/App/SessionProvider";
import { preventBackspaceNavigation } from "@ovrsea/ovrlib/utils/events/preventBackspaceNavigation";
import type { PropsWithChildren } from "react";
import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "@ovrsea/ovrlib/utils/routes/reactRouter";
import { AuthenticationGate } from "@ovrsea/ovrlib/components/Auth/AuthenticationGate";
import type { SearchClientProviderProps } from "@ovrsea/ovrlib/components/Algolia/SearchClientProvider";
import { SearchClientProvider as AlgoliaSearchClientProvider } from "@ovrsea/ovrlib/components/Algolia/SearchClientProvider";
import { CommandBarProvider } from "./components/Hotkeys/CommandBarProvider";
import Atlas from "./Atlas";
import { ATLAS_FEATURE_FLAGS } from "./config";
import { AtlasIntlProvider } from "./AtlasIntlProvider";

const container = createRoot(document.getElementById("root") as HTMLElement);

document.addEventListener("keydown", preventBackspaceNavigation);

type AtlasRenderAppConfigType = {
  ApolloProvider: React.FC<PropsWithChildren<object>>;
  AuthenticationProvider: React.FC<PropsWithChildren<object>>;
  SearchClientProvider?: React.FC<PropsWithChildren<SearchClientProviderProps>>;
  StatusContainer: React.FC<PropsWithChildren<object>>;
};

export const renderApp = (
  App: AppType,
  {
    ApolloProvider,
    AuthenticationProvider,
    SearchClientProvider = AlgoliaSearchClientProvider,
    StatusContainer,
  }: AtlasRenderAppConfigType,
) => {
  console.log("Rendering Atlas renderApp.tsx");

  container.render(
    <StatusContainer>
      <SearchClientProvider>
        <RouterProvider>
          <AuthenticationProvider>
            <AuthTokenProvider>
              <ApolloProvider>
                <SessionProvider>
                  <CommandBarProvider>
                    <AtlasIntlProvider>
                      <App featureFlags={ATLAS_FEATURE_FLAGS}>
                        <AuthenticationGate>
                          <Atlas />
                        </AuthenticationGate>
                      </App>
                    </AtlasIntlProvider>
                  </CommandBarProvider>
                </SessionProvider>
              </ApolloProvider>
            </AuthTokenProvider>
          </AuthenticationProvider>
        </RouterProvider>
      </SearchClientProvider>
    </StatusContainer>,
  );
};
